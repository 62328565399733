/**
 *  This style aims to make the scrollbar experience consistent across
 *  the major browsers. It target 2 main engines,  Firefox and chromium.
 *
 * reference:
 *   [Firefox]
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
 *   [chromium]
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
 */
body[data-scrollbar-always-visible='true'] {
  .hide-scrollbar {
    // firefox
    scrollbar-width: none;
    // chromium
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0 !important;
      background-color: transparent !important;
    }
  }

  .hide-children-scrollbar {
    // firefox
    scrollbar-width: none;
    // chromium
    & *::-webkit-scrollbar,
    & *::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-thumb {
      width: 0;
    }
  }

  .scrollbar-stable {
    --scrollbar-width: 0.5rem;
    --scrollbar-offset: 0.5rem;

    --scrollbar-track-color: transparent;
    --scrollbar-thumb-color: transparent;

    padding-right: var(--scrollbar-offset) !important;

    // firefox
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
    // chromium
    &::-webkit-scrollbar {
      background-color: transparent;
      width: var(--scrollbar-width);
    }

    &:hover {
      --scrollbar-thumb-color: var(--gray-5);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-clip: content-box;
      background-color: var(--scrollbar-thumb-color);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scrollbar-track-color);
    }
  }
  // FIX the Modal component which hides the scrollbar when is open
  &:has(dialog[open]) {
    overflow-y: auto;
  }
}
