p {
  margin-bottom: 1rem;
  margin-top: 0;
}

hr {
  background-color: #d0d0d0;
  border: 0;
  height: 1px;
  margin-block: 1.5rem;
}
// links
a {
  cursor: pointer;
}

a,
a:hover {
  color: var(--primary);
}

a {
  background-color: initial;
  text-decoration: underline;
}

a.link-reset:where(:link, :visited, :hover, :active) {
  display: inline-block;
  text-decoration: none;
  margin-right: 1rem;
  font-style: none;
  color: initial;
  margin: 0;
  padding: 0;
}

// headings
.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: calc(100% + 0.12em);
  margin: 1rem 0;
  opacity: 1;
  text-align: left;
}

h2 {
  font-size: 2.5rem;
  letter-spacing: -0.0875rem;
  line-height: 2.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
  letter-spacing: -0.0525rem;
  line-height: 1.75rem;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
