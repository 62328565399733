/// Opacity
/// Utilities for controlling the opacity of an element.
/// @group effects

$counter: 10;
$step: 10;

@for $i from 0 through $counter {
  /// @group effect
  .opacity-#{$i * $step} {
    opacity: #{$i / 10};
  }
}
