$box-sizes: (
  w: width,
  h: height,
);

@each $rule, $attribute in $box-sizes {
  .#{$rule}-auto {
    #{$attribute}: auto;
  }
  .#{$rule}-full {
    #{$attribute}: 100%;
  }
  .#{$rule}-screen {
    #{$attribute}: 100vw;
  }
  .#{$rule}-min {
    #{$attribute}: min-content;
  }
  .#{$rule}-max {
    #{$attribute}: max-content;
  }
  .#{$rule}-fit {
    #{$attribute}: fit-content;
  }
}

$sizes: 8;
$step: 0.25;

@for $i from 0 through $sizes {
  .w-#{$i} {
    width: #{$i * $step + 'rem'};
  }
  .h-#{$i} {
    height: #{$i * $step + 'rem'};
  }
}
// max-width, max-height
$sizes: 8;
$step: 1;

@for $i from 0 through $sizes {
  .max-h-#{$i}rem {
    max-height: #{$i * $step + 'rem'};
  }

  .max-w-#{$i}rem {
    max-width: #{$i * $step + 'rem'};
  }
}
