.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  padding: 0.5rem;
  width: 100%;
  height: auto;

  background-clip: padding-box;
  background-color: #fff;

  border: 1px solid var(--gray-5);
  border-radius: 0;

  color: var(--focus-color);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
  legend {
    color: inherit;
    display: block;
    font-size: 1rem;
    line-height: inherit;
    margin-bottom: 0.5rem;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
  }
}

form input,
form select,
form textarea {
  padding-left: 1rem;
}

form label {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.25rem;
  letter-spacing: -0.08px;
}

//#region check / radio

.form-check {
  margin: 1rem 0;
  display: block;
  padding-left: 1.25rem;
  position: relative;

  & input[type='checkbox'],
  & input[type='radio'] {
    opacity: 0;
  }

  & .form-check-input {
    margin-left: -1.25rem;
    margin-top: 0.3rem;
    position: absolute;
  }

  & input[type='radio'] + label {
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-left: 1rem;
    position: relative;
  }

  & input[type='radio'] + label:after,
  & input[type='radio'] + label:before {
    border-radius: 50%;
  }

  & input[type='checkbox'] + label:before,
  & input[type='radio'] + label:before {
    content: '';
    background: #fff;
    border: 1px solid #747474;
    display: inline-block;
    height: 1.5rem;
    left: -2.25rem;
    position: absolute;
    width: 1.5rem;
  }

  & input[type='checkbox'] + label:after,
  & input[type='radio'] + label:after {
    content: '';
    display: inline-block;
    height: 0.75rem;
    left: -1.875rem;
    position: absolute;
    top: 0.375rem;
    width: 0.75rem;
  }

  & input[type='checkbox']:checked + label:after,
  & input[type='radio']:checked + label:after {
    background: #000;
  }
}
//#endregion check / radio

//#region Focus
*[tabindex='0']:focus {
  outline: 2px solid var(--focus-color);
  outline-offset: 3px;
  box-shadow: none;
}

.react-select-control:focus-within,
:is(input, select, textarea, button, a) {
  &:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--focus-color);
    outline: 0;
  }
  &:focus.no-active-focus {
    box-shadow: none;
    outline: none;
  }
}

.form-check {
  & input[type='checkbox']:focus + label:before,
  & input[type='radio']:focus + label:before {
    border: 2px solid var(--focus-color);
  }

  & input[type='checkbox'] + label::after {
    left: -2rem;
  }
}

input[type='checkbox']:focus + label:before,
input[type='radio']:focus + label:before {
  border: 2px solid var(--focus-color);
}

input[type='checkbox'] + label::after {
  left: -2rem;
}

//#endregion
