// base
@import './styles/mixins/breakpoints';
@import './styles/variables';
@import './styles/base.scss';
// container
@import './styles/layout.scss';
@import './styles/container.scss';
@import './styles/background.scss';
@import './styles/scrollbar.scss';
// effect
@import './styles/animation.scss';
@import './styles/effect.scss';
// component
@import './styles/form.scss';
@import './styles/list.scss';
// typography
@import './styles/typography.scss';
// box-model
@import './styles/position.scss';
@import './styles/sizing.scss';
@import './styles/border.scss';
@import './styles/spacing.scss';

:root {
  // =============================================
  // Values calculated dynamically by the Navbar
  // =============================================
  --initial-navbar-height: 0;
  --initial-main-height: calc(100vh - var(--initial-navbar-height));
  --main-height: calc(100vh - var(--navbar-height));
  --scroll-margin-top: var(--navbar-height);
  // =============================================
  // base colors
  // =============================================
  --dark: #000;
  --white: #ffffff;
  --maroon: #8c1d40;
  --gold: #ffc627;
  --green: #78be20;
  --blue: #00a3e0;
  --orange: #ff7f32;
  // =============================================
  // gray scale
  // =============================================
  --gray-1: #fafafa;
  --gray-x: #f2f2f2;
  --gray-2: #e8e8e8;
  --gray-3: #d0d0d0;
  --gray-4: #bfbfbf;
  --gray-5: #747474;
  --gray-6: #484848;
  --gray-7: #191919;
  --gray-8: #222222;
  // =============================================
  // alert colors
  // =============================================
  --warning-light: #ffeade;

  --warning-dark: #bd4815;
  --on-warning-dark: var(--white);

  --warning: #de2a0d;
  --on-warning: var(--gray-1);

  --error-light: #f7dddd;
  --error-dark: #cc2f2f;
  // =============================================
  // primary/secondary colors
  // =============================================
  --primary: var(--maroon);
  --on-primary: var(--white);

  --secondary: var(--gold);
  --on-secondary: var(--dark);

  --secondary-2-: var(--green);
  --secondary-3: var(--blue);
  // =============================================
  // major colors
  // =============================================
  --current-major-bg-color: var(--primary);
  --on-current-major-bg-color: var(--on-primary);

  --target-major-bg-color: var(--secondary);
  --on-target-major-bg-color: var(--on-secondary);

  --body-bg-color: var(--gray-1);
  // =============================================
  // Misc
  // =============================================
  --link-color: var(--gray-6);
  --link-color-hover: var(--primary);
  --focus-color: var(--gray-7);
  // =============================================
}

*,
:after,
:before {
  box-sizing: border-box;
}

html * {
  scroll-margin-top: calc(var(--scroll-margin-top) + 1rem);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  // size and space
  margin: 0;
  min-height: 100vh;
  // text
  font-family: Arial, Helvetica, 'Nimbus Sans L', 'Liberation Sans', FreeSans,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-7);
  text-align: left;
  // background
  background-color: var(--body-bg-color);
}

main {
  min-height: var(--main-height);
}

main,
main.container {
  padding-left: 0;
  padding-right: 0;
}

#root {
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}

.y-axis-0 {
  display: none;
}