// Padding and margin
$counter: 20;
$step: 0.25;

$box-positions: (
  t: top,
  r: right,
  l: left,
  b: bottom,
);

$box-spaces: (
  p: padding,
  m: margin,
);

@for $i from 0 through $counter {
  @each $rule, $attribute in $box-spaces {
    //=====================================
    // physical properties
    //=====================================
    // ex .m-1, .p-1, etc...
    .#{$rule}-#{$i} {
      #{$attribute}: #{$i * $step}rem;
    }
    // ex .mx-1, .px-1, etc...
    .#{$rule}x-#{$i} {
      #{$attribute}-left: #{$i * $step}rem;
      #{$attribute}-right: #{$i * $step}rem;
    }
    // ex .my-1, .py-1, etc...
    .#{$rule}y-#{$i} {
      #{$attribute}-top: #{$i * $step}rem;
      #{$attribute}-bottom: #{$i * $step}rem;
    }
    // ex .mt-1, .pt-1, etc...
    @each $pos-rule, $pos-attribute in $box-positions {
      .#{$rule}#{$pos-rule}-#{$i} {
        #{$attribute}-#{$pos-attribute}: #{$i * $step}rem;
      }
    }
    //=====================================
    // logical properties
    //=====================================
    // ex .p-inline-1, .m-inline-1, etc...
    .#{$rule}-inline-#{$i} {
      #{$attribute}-inline: #{$i * $step}rem;
    }
    // ex .p-block-1, .m-block-1, etc...
    .#{$rule}-block-#{$i} {
      #{$attribute}-block: #{$i * $step}rem;
    }
    // ex .ps-1, .ms-1, etc...
    .#{$rule}s-#{$i} {
      #{$attribute}-inline-start: #{$i * $step}rem;
    }
    // ex .pe-1, .me-1, etc...
    .#{$rule}e-#{$i} {
      #{$attribute}-inline-end: #{$i * $step}rem;
    }
  }
}

.m-auto {
  margin: auto;
}

// negative margin
$count: 5;
$step: 0.25;

@for $i from 0 through $count {
  .-m-l-#{$i} {
    margin-left: -#{$i * $step}rem;
  }
}
