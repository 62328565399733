.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

// borders

.rounded-full {
  border-radius: 50%;
}

.border-solid {
  border-style: solid;
}

@for $i from 1 through 7 {
  .border-bottom-gray-#{$i} {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--gray-#{$i});
  }
  .border-gray-#{$i} {
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray-#{$i});
  }
}
