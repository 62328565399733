.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.grid {
  display: grid;
}

// flex

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.self-end {
  align-self: end;
}

.place-self-center {
  place-self: center;
}

.place-content-center {
  place-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

$counter: 16;
$step: 0.25;

@for $i from 0 through $counter {
  .gap-#{$i} {
    gap: #{$i * $step}rem;
  }
}
