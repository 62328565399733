/// Font size
/// Utilities for controlling the font size of an element.
/// @group Typography
.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
/// @group Typography
.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
/// @group Typography
.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
/// @group Typography
.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
/// @group Typography
.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
/// @group Typography
.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
/// @group Typography
.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
/// Font Weight
/// Utilities for controlling the font weight of an element.
$font-sizes: (
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
);

@each $rule, $value in $font-sizes {
  /// @group Typography
  .font-#{$rule} {
    font-weight: #{$value};
  }
}
/// Line Height
/// Utilities for controlling the leading (line height) of an element.
/// @group Typography
.leading-none {
  line-height: 1;
}
/// Line Clamp
/// Utilities for clamping text to a specific number of lines.
@for $value from 1 through 6 {
  /// @group Typography
  .line-clamp-#{$value} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$value};
  }
}
/// @group Typography
.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}
/// Text Align
/// Utilities for controlling the alignment of text.
/// @group Typography
$alignment-list: (right, left, center);
@each $alignment in $alignment-list {
  /// @group Typography
  .text-#{$alignment} {
    text-align: #{$alignment};
  }
}
/// Text Color
/// Utilities for controlling the text color of an element.
/// @group Typography
@for $value from 1 through 7 {
  /// @group Typography
  .text-gray-#{$value} {
    color: var(--gray-#{$value});
  }
  /// @group Typography
  .hover\:text-gray-#{$value}:hover {
    color: var(--gray-#{$value});
  }
}
/// Text Decoration
/// Utilities for controlling the decoration of text.
/// @group Typography
.no-underline {
  text-decoration-line: none;
}
/// Text Transform
/// Utilities for controlling the transformation of text.
$text-transform: (capitalize, uppercase, lowercase, capitalize, none);

@each $value in $text-transform {
  /// @group Typography
  .#{$value} {
    text-transform: #{$value};
  }
}
/// Text wrap
/// Utilities for controlling the text wrap.
$text-wrap: (wrap, nowrap, balance);

@each $value in $text-wrap {
  /// @group Typography
  .text-#{$value} {
    text-wrap: #{$value};
  }
}
