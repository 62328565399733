/// Position
/// Utilities for controlling how an element is positioned in the DOM.
$layouts: (absolute, fixed, sticky, relative);

@each $value in $layouts {
  /// @group Position
  .#{$value} {
    position: #{$value};
  }
}

/// Top / Right / Bottom / Left
/// Utilities for controlling the placement of positioned elements.
$placement: (top, right, left, bottom);

@each $value in $placement {
  /// @group Top-Right-Bottom-Left
  .#{$value}-0 {
    #{$value}: 0;
  }
  /// @group Top-Right-Bottom-Left
  .#{$value}-100 {
    #{$value}: 100%;
  }
}
/// @group Top-Right-Bottom-Left
.inset-x-0 {
  right: 0px;
  left: 0px;
}

/// Z-Index
/// Utilities for controlling the stack order of an element.
$counter: 10;
$step: 10;

@for $i from 1 through $counter {
  $value: $i * $step;
  /// @group Z-Index
  .z-#{$value} {
    z-index: #{$value};
  }
}
