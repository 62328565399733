.bg-white {
  background-color: var(--white);
}

.highlight-gold {
  box-shadow: -0.15em 0 0 #ffc627, 0.15em 0 0 #ffc627;
  background: #ffc627;
  color: #191919;
}

.highlight-black {
  background: #191919;
  box-shadow: -0.15em 0 0 #191919, 0.15em 0 0 #191919;
  color: #fafafa;
}

//#region  custom background
body.topography-pattern {
  background: url(../img/topo-pattern-white.png);
}

body.topography-pattern .topography-container {
  background-color: #ffffff;
}

.bg-topo-white {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-inline: 1px solid #00000029;
  width: fit-content;
  margin: 0 auto;
}

.bg-topo-white:before {
  content: '';
  background: url(../img/topo-pattern-white.png);
  margin-inline: -50vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  isolation: isolate;
  z-index: -1;
}

@media only screen and (max-width: 1260px) {
  .bg-topo-white:before {
    margin-inline: -50vw;
    left: 50%;
    right: 50%;
  }
}

//#endregion custom background
